import { createSlice } from "@reduxjs/toolkit";
import { StorageKey } from "../../types";

export interface IPersistedStorageReducer {
	access_token: string;
	code_verifier: string;
	cookies: string;
	expires_at: string;
	redirect_url_after_login?: string;
	is_admin_mode?: boolean;
	refresh_token: string;
	scopes: string[];
	refresh_token_pending?: boolean | string;
	refresh_token_resolved?: boolean | string;
}

const initialState: IPersistedStorageReducer = {
	access_token: "",
	code_verifier: "",
	expires_at: "",
	is_admin_mode: false,
	refresh_token: "",
	scopes: [],
	cookies: ""
};

const persistedStorageSlice = createSlice({
	name: "persistedStorage",
	initialState,
	reducers: {
		setValueToStorage: (
			state,
			action: {
				payload: { name: StorageKey; value: string | string[] | boolean };
			}
		) => {
			const { name, value } = action.payload;
			(state as Record<StorageKey, string | string[] | boolean>)[name] = value;
		},
		removeValueFromStorage: (state, action: { payload: StorageKey }) => {
			delete (state as Record<StorageKey, string | string[] | boolean>)[
				action.payload
			];
		}
	}
});

export const { setValueToStorage, removeValueFromStorage } =
	persistedStorageSlice.actions;
export default persistedStorageSlice.reducer;
