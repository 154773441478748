import { type NavigateFunction } from "react-router-dom";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import i18n from "../../config/i18n";

import { generateError } from "../../api/helper";
import { assertAPI, fileAPI, participantAPI, testAPI } from "../../api/v2";

import {
	APIError,
	AssertParams,
	DetailsFormData,
	DetailsFormError,
	InternalAssert,
	InternalGroup,
	InternalPaging,
	InternalParticipant,
	InternalPrecondition,
	Participant,
	ParticipantParams,
	QueryFilters,
	QuerySorts,
	RejectWithValue,
	RequestOptions,
	RequiredField,
	ResponseWithPaging,
	ScriptFormData,
	ScriptFormError,
	TabSettings,
	TestBuilderStep,
	TestDTOCreate,
	TestDTORead,
	TestExtendedDTOCreate,
	TestExtendedDTORead,
	TestParams
} from "../../types";

import {
	testBuilder as tb,
	testConsts,
	dateTimeFormat,
	sorting
} from "../../utils/const";
import { formatDateTime } from "../../utils/dateTime";
import { deepCopy, getMinutes, objectEqual } from "../../utils/method";
import {
	validateRequired,
	validateScript,
	validateTestMode,
	validateTimerTime
} from "../../utils/validation";

import { IProjectsReducer } from "./projects";
import moment from "moment";

export type Settings = {
	asserts: TabSettings;
	participants: TabSettings;
};

export interface ITestBuilderReducer {
	allAssertsLoading: boolean;
	allParticipantsLoading: boolean;
	activeStep: number;
	activeGroup: number | string;
	activeParticipantID: number | string;
	initializedAssert: InternalAssert | null;
	modifiedAssert: InternalAssert | null;
	asserts: InternalAssert[];
	assertsToSave: Record<number, boolean>[];
	assertTabActionsDisabled: boolean;
	lastCreatedAssert: {
		id: number | null;
		path: string | null;
	};
	detailsFormData: DetailsFormData;
	detailsFormError: DetailsFormError;
	discardAssert: boolean;
	editingParticipantGroupID: number;
	editMode: boolean;
	error: APIError | null;
	groups: InternalGroup[];
	initialFormData: Record<
		"details" | "script",
		DetailsFormData | ScriptFormData | null
	>;
	isFetching: boolean;
	settings: Settings;
	internalParticipants: InternalParticipant[];
	participants: Partial<Participant>[];
	scriptFormData: ScriptFormData;
	scriptFormError: ScriptFormError;
	shouldSaveAsserts: boolean;
	shouldSaveTestAndNavigate: boolean;
	navigateFromUpgrade: boolean;
	showGlobalLoader: boolean;
	showIncompleteAssertError: boolean;
	showIncompletePreconditionError: boolean;
	steps: TestBuilderStep[];
	testID: number;
	testCUUSage: number;
	changesActive: boolean;
}

const {
	steps: { ASSERTS, DETAILS, PARTICIPANTS, SCRIPT }
} = tb;

const steps = [DETAILS, SCRIPT, PARTICIPANTS, ASSERTS];

const initialState: ITestBuilderReducer = {
	allAssertsLoading: false,
	allParticipantsLoading: false,
	activeGroup: 0,
	activeParticipantID: 0,
	activeStep:
		steps.indexOf(location.hash.substring(1) as TestBuilderStep) + 1 || 1,
	initializedAssert: null,
	modifiedAssert: null,
	asserts: [],
	assertsToSave: [],
	assertTabActionsDisabled: false,
	lastCreatedAssert: {
		id: null,
		path: null
	},
	detailsFormData: {
		increment_strategy: testConsts.STRATEGY.LINEAR,
		mode: testConsts.MODE.PERFORMANCE.key,
		mos_test: false,
		name: "",
		participant_count: 0,
		participant_timeout: "00:15:00",
		start_interval: "00:01:00"
	},
	detailsFormError: {
		name: "",
		participant_timeout: "",
		start_interval: "",
		test_mode: ""
	},
	discardAssert: false,
	editingParticipantGroupID: 0,
	editMode: false,
	error: null,
	groups: [],
	showIncompleteAssertError: false,
	showIncompletePreconditionError: false,
	initialFormData: {
		details: null,
		script: null
	},
	isFetching: false,
	settings: {
		asserts: {
			pagination: {
				currentPage: 1,
				itemsPerPage: 20
			},
			sorting: sorting.NEWEST
		},
		participants: {
			pagination: {
				currentPage: 1,
				itemsPerPage: 20
			},
			showGroups: true,
			sorting: sorting.NEWEST
		}
	},
	internalParticipants: [],
	participants: [],
	scriptFormData: {
		script: ""
	},
	scriptFormError: {
		script: ""
	},
	shouldSaveAsserts: false,
	shouldSaveTestAndNavigate: false,
	navigateFromUpgrade: false,
	showGlobalLoader: false,
	steps,
	testID: 0,
	testCUUSage: 0,
	changesActive: false
};

type activeParticipantAndGroup = {
	activeGroup: number | string;
	activeParticipantID: number | string;
};

type FormDataPayload = {
	formData: DetailsFormData | ScriptFormData;
	step: Exclude<TestBuilderStep, "participants" | "asserts">;
};

type RemoveParticipantPayload = {
	id: string | number;
	groupID: string | number | undefined;
};

type EditParticipantPayload = {
	participant: InternalParticipant;
	id: string | number;
};

type EditGroupPayload = {
	group: InternalGroup;
	id: string | number;
};

type DeletePreconditionViaTagPayload = {
	assertID: number;
	preconditionID: number;
};

type SaveTestBuilderSettingsPayload = {
	tab: TestBuilderStep;
	settings: TabSettings;
};

type TestBuilderSetInitialFormDataPayload = {
	tab: TestBuilderStep;
	data: DetailsFormData | ScriptFormData;
};

type GetAllParams = {
	navigate: NavigateFunction;
	params: ParticipantParams | AssertParams;
	pagerInfo: InternalPaging;
	filters?: QueryFilters;
	sorts?: QuerySorts[];
};

type CreateTestParams = {
	navigate: NavigateFunction;
	params: TestParams;
	test: TestExtendedDTOCreate | TestDTOCreate;
	options?: RequestOptions;
	simpleRequest?: boolean;
};

type UpdateTestParams = {
	navigate: NavigateFunction;
	params: RequiredField<TestParams, "testID">;
	test: TestDTOCreate;
	options?: RequestOptions;
};

type ReadTestParams = {
	navigate: NavigateFunction;
	params: TestParams;
	showGlobalLoader: boolean;
	options?: RequestOptions;
	getScript?: boolean;
};

export const getAllParticipants = createAsyncThunk(
	"testBuilder/getAllParticipants",
	async (
		{ navigate, params, pagerInfo, filters, sorts }: GetAllParams,
		{ rejectWithValue }
	): Promise<ResponseWithPaging<InternalParticipant> | RejectWithValue> => {
		const queryParams = {
			pagination: {
				limit: pagerInfo.itemsPerPage,
				page: pagerInfo.currentPage
			},
			filters,
			sorts
		};

		try {
			const response = await participantAPI.readAll(
				navigate,
				params as ParticipantParams,
				queryParams,
				false
			);

			if (!("results" in response)) {
				throw generateError("Unable to retrieve participants");
			}

			return response;
		} catch (error) {
			return rejectWithValue(error as APIError);
		}
	}
);

export const getAllAsserts = createAsyncThunk(
	"testBuilder/getAllAsserts",
	async (
		{ navigate, params, pagerInfo, filters, sorts }: GetAllParams,
		{ rejectWithValue }
	): Promise<ResponseWithPaging<InternalAssert> | RejectWithValue> => {
		const queryParams = {
			pagination: {
				limit: pagerInfo.itemsPerPage,
				page: pagerInfo.currentPage
			},
			filters,
			sorts
		};

		try {
			const response = await assertAPI.readAll(navigate, params, queryParams);

			if (!("results" in response)) {
				throw generateError("Unable to retrieve asserts");
			}

			return response;
		} catch (error) {
			return rejectWithValue(error as APIError);
		}
	}
);

export const createTest = createAsyncThunk(
	"testBuilder/createTest",
	async (
		{ navigate, params, test, options, simpleRequest }: CreateTestParams,
		{ rejectWithValue }
	): Promise<TestDTOCreate | TestExtendedDTOCreate | {} | RejectWithValue> => {
		try {
			const response = simpleRequest
				? await testAPI.create(
						navigate,
						params,
						test as TestDTOCreate,
						options || {}
					)
				: await testAPI.createExtended(
						navigate,
						params,
						test as TestExtendedDTOCreate,
						options || {}
					);

			return response;
		} catch (error) {
			return rejectWithValue(error as APIError);
		}
	}
);

export const updateTest = createAsyncThunk(
	"testBuilder/updateTest",
	async (
		{ navigate, params, test, options }: UpdateTestParams,
		{ rejectWithValue }
	): Promise<TestDTOCreate | TestExtendedDTOCreate | {} | RejectWithValue> => {
		try {
			const response = await testAPI.update(
				navigate,
				params,
				test,
				typeof options !== "undefined" ? options : {}
			);

			return response;
		} catch (error) {
			return rejectWithValue(error as APIError);
		}
	}
);

export const readTest = createAsyncThunk(
	"testBuilder/readTest",
	async (
		{ navigate, params, showGlobalLoader, options, getScript }: ReadTestParams,
		{ rejectWithValue, dispatch }
	): Promise<TestExtendedDTORead | RejectWithValue> => {
		try {
			setShowGlobalLoader({ showGlobalLoader });

			const response = await testAPI.readExtended(navigate, params, options);

			if (!("test" in response)) {
				throw generateError("Unable to retrieve test data");
			}

			dispatch(setTestCUUsage({ cu: response.test.total_cu_count }));

			if (getScript) {
				params.fileID = response.test.script_file_id;

				const script = await fileAPI.read(
					navigate,
					params,
					typeof options !== "undefined" ? options : {}
				);

				if (!("test" in response && "content" in script)) {
					throw generateError("Unable to retrieve test data");
				}

				return {
					...response,
					test: {
						...response.test,
						script: script.content
					}
				};
			}

			return response;
		} catch (error) {
			return rejectWithValue(error as APIError);
		}
	}
);

const testBuilderSlice = createSlice({
	name: "testBuilder",
	initialState,
	reducers: {
		clearTestBuilderData: () => {
			return { ...initialState, activeStep: 1 };
		},
		testBuilderPrevStep: state => {
			state.activeStep = state.activeStep === 1 ? 1 : state.activeStep - 1;
		},
		testBuilderNextStep: state => {
			state.activeStep = state.activeStep + 1;
		},
		testBuilderSetActiveParticipantAndGroup: (
			state,
			action: { payload: activeParticipantAndGroup }
		) => {
			state.activeGroup = action.payload.activeGroup;
			state.activeParticipantID = action.payload.activeParticipantID;
		},
		testBuilderGoToStep: (state, action: { payload: { step: number } }) => {
			state.activeStep = action.payload.step;
		},
		testBuilderSetFormData: (state, action: { payload: FormDataPayload }) => {
			if (action.payload.step === "script") {
				state.scriptFormData = action.payload.formData as ScriptFormData;
			} else if (action.payload.step === "details") {
				state.detailsFormData = action.payload.formData as DetailsFormData;
			}
		},
		updateDetailsFormError: (
			state,
			action: { payload: { formError: DetailsFormError } }
		) => {
			state.detailsFormError = action.payload.formError;
		},
		validateDetailsTab: (
			state,
			action: { payload: { projects: IProjectsReducer } }
		) => {
			const { projects } = action.payload;

			const formError = {
				name: i18n.t(validateRequired(state.detailsFormData.name)),
				start_interval: i18n.t(
					validateTimerTime(
						state.detailsFormData.start_interval as string,
						"24h",
						true
					)
				),
				participant_timeout: i18n.t(
					validateTimerTime(
						state.detailsFormData.participant_timeout as string,
						state.detailsFormData?.mos_test &&
							getMinutes(
								(projects.planLimits?.max_test_duration as string) || ""
							) > 45
							? "45m"
							: (projects.planLimits?.max_test_duration as string)
					)
				),
				test_mode: i18n.t(
					validateTestMode(
						state.detailsFormData.mode,
						projects.planLimits
							? projects.planLimits["session_recording_access"]
							: false
					)
				)
			};

			state.detailsFormError = formError;
		},
		validateScriptTab: (state, action: { payload: string }) => {
			const formError = {
				script: validateScript(action.payload)
			};

			state.scriptFormError = formError;
		},
		setTestBuilderParticipant: (
			state,
			action: { payload: { participant: InternalParticipant } }
		) => {
			state.internalParticipants.push(action.payload.participant);
		},
		removeTestBuilderParticipant: (
			state,
			action: { payload: RemoveParticipantPayload }
		) => {
			const newGroups = [...state.groups];

			const indexOfGroupToRemoveParticipantFrom = newGroups.findIndex(
				g => g.id === action.payload.groupID
			);

			const groupToRemoveParticipantFrom =
				newGroups[indexOfGroupToRemoveParticipantFrom];

			if (groupToRemoveParticipantFrom) {
				groupToRemoveParticipantFrom.participants =
					groupToRemoveParticipantFrom.participants?.filter(
						p => p.id !== action.payload.id
					);
			}

			state.groups = newGroups;
			state.internalParticipants = state.internalParticipants.filter(
				p => p.id !== action.payload.id
			);
		},
		editTestBuilderParticipant: (
			state,
			action: { payload: EditParticipantPayload }
		) => {
			const participantPosition = state.internalParticipants.findIndex(
				p => p.id === action.payload.id
			);
			const updatedList = state.internalParticipants.filter(
				p => p.id !== action.payload.id
			);
			const updatedParticipant = { ...action.payload.participant };

			updatedParticipant.updated = Date.now();

			updatedList.splice(participantPosition, 0, updatedParticipant);

			state.internalParticipants = !objectEqual(
				state.internalParticipants[participantPosition],
				action.payload.participant
			)
				? updatedList
				: state.internalParticipants;
		},
		setTestBuilderGroup: (
			state,
			action: { payload: { group: InternalGroup } }
		) => {
			const updatedGroups = [...state.groups];

			const modifiedGroup = {
				...action.payload.group,
				isActive: true
			};
			updatedGroups.push(modifiedGroup);

			state.groups = updatedGroups;
		},
		removeTestBuilderGroup: (
			state,
			action: { payload: { id: string | number } }
		) => {
			// Delete all associated participants
			// with this group if there are any
			const participantsToDelete =
				state.internalParticipants
					.map(p => {
						if (p.group_id === action.payload.id) return p.id;

						return;
					})
					.filter(Boolean) || [];

			const filteredParticipants =
				participantsToDelete.length > 0
					? [...state.internalParticipants].filter(
							p => !participantsToDelete.includes(p.id)
						)
					: state.internalParticipants;

			state.groups = state.groups.filter(g => g.id !== action.payload.id);
			state.internalParticipants = filteredParticipants;
		},
		editTestBuilderGroup: (state, action: { payload: EditGroupPayload }) => {
			const updatedGroupList = state.groups.filter(
				p => p.id !== action.payload.id
			);

			const groupPosition = state.groups.findIndex(
				p => p.id === action.payload.id
			);

			const updatedGroup = {
				...action.payload.group,
				participants: [],
				isActive: action.payload.group.isActive
			};

			if (
				state.groups[groupPosition].count !== updatedGroup.count ||
				state.groups[groupPosition].name !== updatedGroup.name
			) {
				updatedGroup.updated = Date.now();
			}

			updatedGroupList.push(updatedGroup);

			state.groups = !objectEqual(
				state.groups[groupPosition],
				action.payload.group
			)
				? updatedGroupList
				: state.groups;
		},
		allowAssertCreationToast: (
			state,
			action: {
				payload: { assert: { id: number | null; path: string | null } };
			}
		) => {
			state.lastCreatedAssert = action.payload.assert;
		},
		initializeAssert: state => {
			state.initializedAssert = {
				path: "",
				expected: "",
				operator: "",

				created: Date.now(),
				updated: Date.now(),
				id: Date.now(),

				formError: {
					aggregator: "",
					expected: "",
					measurement: "",
					operator: "",
					statistic: "",
					timecardName: ""
				},

				preconditions: [],
				preconditionError: false
			};
		},
		cancelInitializedAssert: state => {
			state.initializedAssert = null;
		},
		updateInitializedAssert: (
			state,
			action: { payload: { assert: InternalAssert } }
		) => {
			state.initializedAssert = action.payload.assert;
		},
		saveInitializedAssert: state => {
			const newAssert = deepCopy(state.initializedAssert) as InternalAssert;

			delete newAssert?.preconditionError;
			delete newAssert?.formError;

			state.asserts.unshift(newAssert);
			state.initializedAssert = null;
		},
		addInitializedAssertPrecondition: (
			state,
			action: { payload: { precondition: InternalPrecondition } }
		) => {
			state.initializedAssert!.preconditions?.push(action.payload.precondition);
		},
		updateInitializedAssertPrecondition: (
			state,
			action: { payload: { precondition: InternalPrecondition } }
		) => {
			const updatedPreconditions = (
				state.initializedAssert as InternalAssert
			).preconditions.map(p =>
				p.id !== action.payload.precondition.id
					? p
					: action.payload.precondition
			);

			state.initializedAssert!.preconditions = updatedPreconditions;
		},
		deleteInitializedAssertPrecondition: (
			state,
			action: { payload: { preconditionID: number } }
		) => {
			const updatedPreconditions =
				state.initializedAssert!.preconditions.filter(
					p => p.id !== action.payload.preconditionID
				);

			state.initializedAssert!.preconditions = updatedPreconditions;
		},
		addModifiedAssert: (state, action: { payload: { id: number } }) => {
			const modifiedAssert = deepCopy(
				state.asserts.find(a => a.id === action.payload.id)
			) as InternalAssert;

			modifiedAssert.formError = {
				aggregator: "",
				measurement: "",
				expected: "",
				operator: "",
				statistic: "",
				timecardName: ""
			};

			modifiedAssert.preconditionError = false;

			if (!modifiedAssert.preconditions) {
				modifiedAssert.preconditions = [];
			}

			state.modifiedAssert = modifiedAssert;
		},
		updateModifiedAssert: (
			state,
			action: { payload: { assert: InternalAssert } }
		) => {
			state.modifiedAssert = action.payload.assert;
		},
		saveModifiedAssert: state => {
			let hasReplaced = false;

			for (let i = 0; i < state.asserts.length && !hasReplaced; i++) {
				if (state.modifiedAssert?.id === state.asserts[i].id) {
					const updatedAssert = deepCopy(
						state.modifiedAssert
					) as InternalAssert;

					delete updatedAssert.formError;
					delete updatedAssert.preconditionError;
					updatedAssert.updated = Date.now();

					state.asserts[i] = updatedAssert;
					hasReplaced = true;
				}
			}

			state.modifiedAssert = null;
		},
		cancelModifiedAssert: state => {
			state.modifiedAssert = null;
		},
		deleteModifiedAssert: state => {
			state.asserts = state.asserts.filter(
				a => a.id !== state.modifiedAssert?.id
			);

			state.modifiedAssert = null;
		},
		addModifiedAssertPrecondition: (
			state,
			action: { payload: { precondition: InternalPrecondition } }
		) => {
			state.modifiedAssert!.preconditions?.push(action.payload.precondition);
		},
		updateModifiedAssertPrecondition: (
			state,
			action: { payload: { precondition: InternalPrecondition } }
		) => {
			const updatedPreconditions = state.modifiedAssert!.preconditions.map(p =>
				p.id !== action.payload.precondition.id
					? p
					: action.payload.precondition
			);
			state.modifiedAssert!.preconditions = updatedPreconditions;
		},
		deleteModifiedAssertPrecondition: (
			state,
			action: { payload: { preconditionID: number } }
		) => {
			const updatedPreconditions = state.modifiedAssert!.preconditions.filter(
				p => p.id !== action.payload.preconditionID
			);

			state.modifiedAssert!.preconditions = updatedPreconditions;
		},
		allowShowingIncompleteAssertError: (
			state,
			action: { payload: { showError: boolean } }
		) => {
			state.showIncompleteAssertError = action.payload.showError;
		},
		allowShowingIncompletePrecinditionError: (
			state,
			action: { payload: { showError: boolean } }
		) => {
			state.showIncompletePreconditionError = action.payload.showError;
		},
		copyAssert: (state, action: { payload: { assert: InternalAssert } }) => {
			state.asserts.unshift(action.payload.assert);
		},
		deleteAssertCreateFlow: (state, action: { payload: { id: number } }) => {
			state.asserts = state.asserts.filter(a => a.id !== action.payload.id);
		},
		setAssertToSave: (state, action: { payload: Record<number, boolean> }) => {
			state.assertsToSave = [
				...state.assertsToSave.filter(
					item => !Object.keys(action.payload).some(key => key in item)
				),
				action.payload
			];
		},
		setShouldSaveAsserts: (state, action: { payload: boolean }) => {
			state.shouldSaveAsserts = action.payload;
		},
		discardChangesInAssert: (state, action: { payload: boolean }) => {
			state.discardAssert = action.payload;
		},
		disableAssertTab: (state, action: { payload: boolean }) => {
			state.assertTabActionsDisabled = action.payload;
		},
		setShouldSaveTestAndNavigate: (state, action: { payload: boolean }) => {
			state.shouldSaveTestAndNavigate = action.payload;
		},
		deletePreconditionViaTag: (
			state,
			action: { payload: DeletePreconditionViaTagPayload }
		) => {
			const cAssert = state.asserts.findIndex(
				a => a.id === action.payload.assertID
			);

			const uAssert = state.asserts[cAssert];
			uAssert.preconditions = uAssert.preconditions.filter(
				p => p.id !== action.payload.preconditionID
			);

			state.asserts = state.asserts.filter(
				a => a.id !== action.payload.assertID
			);
			state.asserts.splice(cAssert, 0, uAssert);
		},
		setTestCUUsage: (state, action: { payload: { cu: number } }) => {
			state.testCUUSage = action.payload.cu;
		},
		saveTestBuilderSettings: (
			state,
			action: { payload: SaveTestBuilderSettingsPayload }
		) => {
			const { settings: newSettings, tab } = action.payload;

			state.settings = {
				...state.settings,
				[tab]: {
					...state.settings[tab as keyof Settings],
					filterValue: newSettings.filterValue,
					pagination: newSettings.pagination,
					showGroups: newSettings.showGroups,
					sorting: newSettings.sorting
				}
			};
		},
		setEditMode: state => {
			state.editMode = true;
		},
		testBuilderSetInitialFormData: (
			state,
			action: { payload: TestBuilderSetInitialFormDataPayload }
		) => {
			state.initialFormData = {
				...state.initialFormData,
				[action.payload.tab]: action.payload.data
			};
		},
		setEditingParticipantGroupID: (
			state,
			action: { payload: { id: number } }
		) => {
			state.editingParticipantGroupID = action.payload.id;
		},
		setNavigateFromUpgrade: (
			state,
			action: { payload: { shouldSave: boolean } }
		) => {
			state.navigateFromUpgrade = action.payload.shouldSave;
		},
		setUnsavedChangesActive: (
			state,
			action: { payload: { changesActive: boolean } }
		) => {
			state.changesActive = action.payload.changesActive;
		},
		setShowGlobalLoader: (
			state,
			action: { payload: { showGlobalLoader: boolean } }
		) => {
			state.showGlobalLoader = action.payload.showGlobalLoader;
		}
	},
	extraReducers(builder) {
		builder
			.addCase(getAllParticipants.pending, state => {
				state.allParticipantsLoading = true;
				state.error = null;
			})
			.addCase(getAllParticipants.fulfilled, (state, action) => {
				state.allParticipantsLoading = false;
				state.participants = (
					action.payload as ResponseWithPaging<InternalParticipant>
				).results;
			})
			.addCase(getAllParticipants.rejected, (state, action) => {
				state.allParticipantsLoading = false;
				state.error = action.payload as APIError;
			})
			.addCase(getAllAsserts.pending, state => {
				state.allAssertsLoading = true;
				state.error = null;
			})
			.addCase(getAllAsserts.fulfilled, (state, action) => {
				state.allAssertsLoading = false;
				state.asserts = (
					action.payload as ResponseWithPaging<InternalAssert>
				).results;
			})
			.addCase(getAllAsserts.rejected, (state, action) => {
				state.allAssertsLoading = false;
				state.error = action.payload as APIError;
			})
			.addCase(createTest.pending, state => {
				state.isFetching = true;
				state.error = null;
			})
			.addCase(createTest.fulfilled, state => {
				state.isFetching = false;
			})
			.addCase(createTest.rejected, (state, action) => {
				state.isFetching = false;
				state.error = action.payload as APIError;
				state.showGlobalLoader = false;
			})
			.addCase(updateTest.fulfilled, (state, action) => {
				const test = action.payload as TestDTORead;

				const updatedDetails = {
					increment_strategy: test.increment_strategy as string,
					mode: test.mode,
					mos_test: test.mos_test,
					name: test.name,
					participant_count: test.participant_count,
					participant_timeout: formatDateTime(
						test.participant_timeout,
						dateTimeFormat.TIME
					),
					start_interval: formatDateTime(
						test.start_interval,
						dateTimeFormat.TIME
					),
					created: formatDateTime(
						test.created as string,
						dateTimeFormat.MONTH_DAY_YEAR_TIME
					),
					updated: formatDateTime(
						test.updated as string,
						dateTimeFormat.MONTH_DAY_YEAR_TIME
					)
				};

				state.isFetching = false;
				state.detailsFormData = updatedDetails as DetailsFormData;
				state.initialFormData.details = updatedDetails as DetailsFormData;
			})
			.addCase(updateTest.rejected, (state, action) => {
				state.isFetching = false;
				state.error = action.payload as APIError;
				state.showGlobalLoader = false;
			})
			.addCase(readTest.pending, state => {
				state.isFetching = true;
				state.error = null;
			})
			.addCase(readTest.fulfilled, (state, action) => {
				const { asserts, groups, participants, test } =
					action.payload as TestExtendedDTORead;

				if (Array.isArray(asserts)) {
					asserts.forEach(a => {
						a.created = moment(a.created).unix();
						a.updated = moment(a.updated).unix();
					});
				}

				if (Array.isArray(groups)) {
					groups.forEach(g => {
						g.created = moment(g.created).unix();
						g.updated = moment(g.updated).unix();
					});
				}

				if (Array.isArray(participants)) {
					participants.forEach(p => {
						p.created = moment(p.created).unix();
						p.updated = moment(p.updated).unix();
					});
				}

				const groupedParticipants: InternalParticipant[] = [];

				((groups as InternalGroup[]) || []).map(group => {
					group.participants?.forEach(p => {
						groupedParticipants.push(p);
					});
				});

				state.asserts = asserts || [];
				state.groups = groups || [];
				state.isFetching = false;
				state.testID = test.id;
				state.testCUUSage = test.total_cu_count;
				state.showGlobalLoader = false;
				state.detailsFormData = {
					increment_strategy: test.increment_strategy,
					mode: test.mode,
					mos_test: test.mos_test,
					name: test.name,
					participant_count: test.participant_count,
					participant_timeout: formatDateTime(
						test.participant_timeout,
						dateTimeFormat.TIME
					),
					start_interval: formatDateTime(
						test.start_interval,
						dateTimeFormat.TIME
					),
					created: formatDateTime(
						test.created as string,
						dateTimeFormat.MONTH_DAY_YEAR_TIME
					),
					updated: formatDateTime(
						test.updated as string,
						dateTimeFormat.MONTH_DAY_YEAR_TIME
					)
				};
				state.internalParticipants =
					[...(participants || []), ...groupedParticipants] || [];
				state.scriptFormData = {
					script: test.script || state.scriptFormData.script
				};
				state.initialFormData.script = {
					script: test.script || state.scriptFormData.script
				};
				state.settings = {
					...state.settings,
					...(!state.settings.asserts.pagination.totalItems && {
						asserts: {
							...state.settings.asserts,
							pagination: {
								...state.settings.asserts.pagination,
								totalItems: (action.payload as TestExtendedDTORead)
									.assert_pagination.total_items,
								currentPage: (action.payload as TestExtendedDTORead)
									.assert_pagination.page
							}
						}
					}),
					participants: {
						...state.settings.participants,
						pagination: {
							...state.settings.participants.pagination,
							totalItems: (action.payload as TestExtendedDTORead)
								.participant_pagination.total_items,
							currentPage: (action.payload as TestExtendedDTORead)
								.participant_pagination.page
						}
					}
				};
			})
			.addCase(readTest.rejected, (state, action) => {
				state.isFetching = false;
				state.error = action.payload as APIError;
				state.showGlobalLoader = false;
			});
	}
});

export default testBuilderSlice.reducer;

export const {
	addInitializedAssertPrecondition,
	addModifiedAssert,
	addModifiedAssertPrecondition,
	allowAssertCreationToast,
	allowShowingIncompleteAssertError,
	allowShowingIncompletePrecinditionError,
	cancelInitializedAssert,
	cancelModifiedAssert,
	clearTestBuilderData,
	copyAssert,
	deleteAssertCreateFlow,
	deleteInitializedAssertPrecondition,
	deleteModifiedAssert,
	deleteModifiedAssertPrecondition,
	deletePreconditionViaTag,
	discardChangesInAssert,
	disableAssertTab,
	editTestBuilderGroup,
	editTestBuilderParticipant,
	initializeAssert,
	removeTestBuilderGroup,
	removeTestBuilderParticipant,
	saveInitializedAssert,
	saveModifiedAssert,
	saveTestBuilderSettings,
	setAssertToSave,
	setEditingParticipantGroupID,
	setEditMode,
	setNavigateFromUpgrade,
	setShouldSaveAsserts,
	setShouldSaveTestAndNavigate,
	setShowGlobalLoader,
	setTestBuilderGroup,
	setTestBuilderParticipant,
	setTestCUUsage,
	setUnsavedChangesActive,
	testBuilderGoToStep,
	testBuilderNextStep,
	testBuilderPrevStep,
	testBuilderSetActiveParticipantAndGroup,
	updateDetailsFormError,
	testBuilderSetFormData,
	testBuilderSetInitialFormData,
	updateInitializedAssert,
	updateInitializedAssertPrecondition,
	updateModifiedAssert,
	updateModifiedAssertPrecondition,
	validateDetailsTab,
	validateScriptTab
} = testBuilderSlice.actions;
