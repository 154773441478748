import { type NavigateFunction } from "react-router-dom";

import moment from "moment";

import { routes } from "../../router/routes";
import {
	ActiveRun,
	APIError,
	Filters,
	Pagination,
	ProjectParams,
	RequestOptions,
	ResponseWithPaging,
	RunParams
} from "../../types";
import { queryKeys as qk } from "../../utils/const";
import {
	createQuery,
	createRoute,
	getOffset,
	setQueryParam
} from "../../utils/request";

import { crud } from "./crud";

const createRun = (
	navigate: NavigateFunction,
	params: RunParams,
	options: RequestOptions
): Promise<ActiveRun | {} | APIError> => {
	const { projectID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.RUNS
	);

	return crud.CREATE<ActiveRun>(navigate, route, {}, options);
};

const getRun = (
	navigate: NavigateFunction,
	params: Required<RunParams>,
	options: RequestOptions
): Promise<ActiveRun | APIError> => {
	const { projectID, runID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.RUNS,
		runID
	);

	return crud.READ<ActiveRun>(navigate, route, options);
};

const getAllTestRuns = (
	navigate: NavigateFunction,
	params: Required<RunParams>,
	queryParams: {
		describe: boolean;
		filters?: Required<Filters>;
		pagination?: Pagination;
		labels?: string[];
	},
	options: RequestOptions
): Promise<ResponseWithPaging<ActiveRun> | APIError> => {
	const { projectID, testID } = params;
	let route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.RUNS
	);

	const { describe, filters, pagination, labels } = queryParams;
	const { dateFrom, dateTo } = filters || {};
	const { limit, page } = pagination || {};

	route += createQuery(
		setQueryParam(qk.DESCRIBE, describe),
		setQueryParam(qk.LIMIT, limit || 0),
		setQueryParam(qk.OFFSET, getOffset(limit || 0, page || 0)),
		setQueryParam(
			qk.FILTER.DATE_CREATED_FROM,
			(dateFrom && moment.utc(dateFrom).unix()) || ""
		),
		setQueryParam(
			qk.FILTER.DATE_CREATED_TO,
			(dateTo && moment.utc(dateTo).unix()) || ""
		),
		setQueryParam(qk.ORDER_BY, "-id")
	);

	const labelsQuery = labels
		? labels.map(label => createQuery(setQueryParam(qk.FILTER.LABEL, label)))
		: [];
	route += labelsQuery.join("").replaceAll("?", "&");

	return crud.READ<ResponseWithPaging<ActiveRun>>(navigate, route, options);
};

const getAllProjectRuns = (
	navigate: NavigateFunction,
	params: ProjectParams,
	queryParams: {
		active: boolean;
		describe: boolean;
		filter_finished: boolean;
		filters?: Required<Filters>;
		labels?: string[];
		pagination?: Pagination;
	},
	options: RequestOptions
): Promise<ResponseWithPaging<ActiveRun> | APIError> => {
	const { projectID } = params;
	const { active, describe, filter_finished, filters, labels, pagination } =
		queryParams;
	const { dateFrom, dateTo, name, testMode } = filters || {};
	const { limit, page } = pagination || {};

	let route = createRoute(true, routes.PROJECTS, projectID, routes.RUNS);

	route += createQuery(
		setQueryParam(qk.DESCRIBE, describe),
		setQueryParam(qk.LIMIT, limit || 0),
		setQueryParam(qk.OFFSET, getOffset(limit || 0, page || 0)),
		setQueryParam(qk.FILTER.ACTIVE_RUNS, active),
		setQueryParam(qk.FILTER.FINISHED, filter_finished),
		setQueryParam(qk.FILTER.TEST_NAME, name || ""),
		setQueryParam(qk.FILTER.TEST_MODE, testMode || ""),
		setQueryParam(
			qk.FILTER.DATE_CREATED_FROM,
			(dateFrom && moment.utc(dateFrom).unix()) || ""
		),
		setQueryParam(
			qk.FILTER.DATE_CREATED_TO,
			(dateTo && moment.utc(dateTo).unix()) || ""
		),
		setQueryParam(qk.ORDER_BY, "-id")
	);

	const labelsQuery = labels
		? labels.map(label => createQuery(setQueryParam(qk.FILTER.LABEL, label)))
		: [];
	route += labelsQuery.join("").replaceAll("?", "&");

	return crud.READ<ResponseWithPaging<ActiveRun>>(navigate, route, options);
};

const stopRun = (
	navigate: NavigateFunction,
	params: Required<RunParams>,
	options: RequestOptions
): Promise<void | {} | APIError> => {
	const { projectID, runID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.RUNS,
		runID,
		routes.STOP
	);

	return crud.CREATE(navigate, route, {}, options);
};

const runAPI = {
	TEST: {
		create: createRun,
		read: getRun,
		readAll: getAllTestRuns,
		stop: stopRun
	},
	PROJECT: {
		readAll: getAllProjectRuns
	}
};

export default runAPI;
