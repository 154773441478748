import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { type NavigateFunction } from "react-router-dom";

import { avatarAPI } from "../../api/mediaAPI";
import projectAPI from "../../api/v2/project";
import tokenAPI from "../../api/v2/token";

import {
	setProjectID,
	setProjectLanguage,
	setSubscriptionPlan
} from "../../services/crisp";

import {
	APIError,
	Token,
	ProjectData,
	ProjectParams,
	RequestOptions,
	ResponseWithPaging,
	TokenParams,
	PlanLimits,
	ComputeUnits,
	LabelData
} from "../../types";

import { zeroTimeValue } from "../../utils/const";
import { arrayFind } from "../../utils/method";

import { store } from "../store/configureStore";

export interface IProjectsReducer {
	activeProject: ProjectData;
	tokens: Token[];
	createProjectAvatarBlob: Blob | null;
	computeUnits: ComputeUnits | null | undefined;
	errorMessage: APIError | null;
	isFetching: boolean;
	planLimits: PlanLimits | null;
	projectData: ResponseWithPaging<ProjectData> | null;
	projectAvatars: Record<number, string | undefined>;
}

const initialState: IProjectsReducer = {
	activeProject: {} as ProjectData,
	tokens: [],
	createProjectAvatarBlob: null,
	computeUnits: null,
	errorMessage: null,
	isFetching: false,
	planLimits: null,
	projectData: null,
	projectAvatars: {}
};

export const fetchProjects = createAsyncThunk(
	"projects/fetchProjects",
	async (
		{
			navigate,
			options,
			shouldFetchAvatars = false
		}: {
			navigate: NavigateFunction;
			options?: RequestOptions;
			shouldFetchAvatars?: boolean;
		},
		{ dispatch, rejectWithValue }
	): Promise<ResponseWithPaging<ProjectData> | APIError | {}> => {
		try {
			const response = await projectAPI.readAll(navigate, {}, options || {});

			if (
				shouldFetchAvatars &&
				(response as ResponseWithPaging<ProjectData>).results
			) {
				(response as ResponseWithPaging<ProjectData>).results.forEach(
					(item: ProjectData) => {
						if (item.avatar) {
							dispatch(
								readProjectAvatar({ navigate, projectID: item.id, options })
							);
						}
					}
				);
			}

			return response;
		} catch (error) {
			return rejectWithValue(error as APIError);
		}
	}
);

export const readProjectAvatar = createAsyncThunk(
	"projects/readProjectAvatar",
	async (
		{
			navigate,
			projectID,
			options
		}: {
			navigate: NavigateFunction;
			projectID: number;
			options?: RequestOptions;
		},
		{ rejectWithValue }
	) => {
		try {
			const projectBlob = (await avatarAPI.project.read(
				navigate,
				{ projectID },
				options
			)) as Blob;
			return { projectBlob, projectID };
		} catch (error) {
			return rejectWithValue(error as APIError);
		}
	}
);

export const fetchTokens = createAsyncThunk(
	"projects/fetchTokens",
	async (
		{
			navigate,
			params,
			options
		}: {
			navigate: NavigateFunction;
			params: TokenParams;
			options: RequestOptions;
		},
		{ rejectWithValue }
	): Promise<Token[] | APIError | {}> => {
		try {
			return await tokenAPI.readAll(navigate, params, options);
		} catch (error) {
			return rejectWithValue(error as APIError);
		}
	}
);

export const getPlanLimitsAndUsage = createAsyncThunk(
	"projects/getPlanLimitsAndUsage",
	async (
		{
			navigate,
			params,
			options
		}: {
			navigate: NavigateFunction;
			params: ProjectParams;
			options?: RequestOptions;
		},
		{ rejectWithValue }
	): Promise<ProjectData | APIError | {}> => {
		try {
			const response = await projectAPI.read(
				navigate,
				params,
				options as RequestOptions
			);

			return response;
		} catch (error) {
			return rejectWithValue(error as APIError);
		}
	}
);

export const setActiveProject = createAsyncThunk(
	"projects/setActiveProject",
	async (
		{
			navigate,
			projectID = 0,
			fetchPlanLimitsAndUsage = true
		}: {
			navigate: NavigateFunction;
			projectID?: string | number;
			fetchPlanLimitsAndUsage?: boolean;
		},
		{ dispatch }
	): Promise<ProjectData | {}> => {
		const { projects } = store.getState();
		const { results } = projects.projectData || {};

		if (!Array.isArray(results)) return {};

		const activeProject =
			(arrayFind(
				results,
				"id",
				parseInt(projectID as string, 10)
			) as ProjectData) || {};

		if (activeProject.id) {
			const { id, language, subscription, trial_expired, trial_started } =
				activeProject;
			const { payment_plan, payment_status } = subscription || {};
			const hasNoPlan =
				!payment_plan && (trial_expired || trial_started === zeroTimeValue);

			setProjectID(id);
			setProjectLanguage(language);
			setSubscriptionPlan(
				payment_plan as string,
				payment_status as string,
				hasNoPlan
			);

			if (fetchPlanLimitsAndUsage) {
				dispatch(
					getPlanLimitsAndUsage({ navigate, params: { projectID: id } })
				);
			}
		}

		return activeProject;
	}
);

const projectsSlice = createSlice({
	name: "projects",
	initialState,
	reducers: {
		addProjectToProjectData: (state, action: { payload: ProjectData }) => {
			if (!state.projectData?.results) return;

			state.projectData.results = [
				...state.projectData.results,
				action.payload
			];
		},
		clearProjectData: () => {
			return initialState;
		},
		clearCUInfo: state => {
			state.computeUnits = null;
			state.planLimits = null;
		},
		setProjectAvatarBlob: (state, action: { payload: Blob | null }) => {
			state.createProjectAvatarBlob = action.payload;
		},
		setProjectLabels: (state, action: { payload: LabelData[] }) => {
			state.activeProject.labels = action.payload;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchProjects.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(fetchProjects.fulfilled, (state, action) => {
				if ("results" in action.payload) {
					state.projectData = action.payload;
				}
				state.isFetching = false;
			})
			.addCase(fetchProjects.rejected, (state, action) => {
				state.errorMessage = (action.payload as APIError) || null;
				state.isFetching = false;
			})
			.addCase(readProjectAvatar.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(readProjectAvatar.fulfilled, (state, action) => {
				const { projectBlob, projectID } = action.payload;
				const avatarUrl =
					projectBlob.size > 0 ? URL.createObjectURL(projectBlob) : undefined;
				state.projectAvatars[projectID] = avatarUrl;
				state.isFetching = false;
			})
			.addCase(readProjectAvatar.rejected, (state, action) => {
				state.errorMessage = (action.payload as APIError) || null;
				state.isFetching = false;
			})
			.addCase(fetchTokens.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(fetchTokens.fulfilled, (state, action) => {
				state.tokens = action.payload as Token[];
				state.isFetching = false;
			})
			.addCase(fetchTokens.rejected, (state, action) => {
				state.errorMessage = (action.payload as APIError) || null;
				state.isFetching = false;
			})
			.addCase(getPlanLimitsAndUsage.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getPlanLimitsAndUsage.fulfilled, (state, action) => {
				const { compute_unit_usage, plan_limits } =
					action.payload as ProjectData;

				state.computeUnits = compute_unit_usage;
				state.planLimits = plan_limits as PlanLimits;
				state.isFetching = false;
				state.activeProject = action.payload as ProjectData;
			})
			.addCase(getPlanLimitsAndUsage.rejected, (state, action) => {
				state.errorMessage = (action.payload as APIError) || null;
				state.isFetching = false;
			})
			.addCase(setActiveProject.fulfilled, (state, action) => {
				state.activeProject = action.payload as ProjectData;
			});
	}
});

export const {
	setProjectLabels,
	addProjectToProjectData,
	clearProjectData,
	clearCUInfo,
	setProjectAvatarBlob
} = projectsSlice.actions;

export default projectsSlice.reducer;
