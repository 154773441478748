import { toast } from "react-toastify";
import { ComponentType } from "react";

import moment from "moment";
import { default as i18next } from "i18next";

import { Option, SVGIcon } from "ui-kit";

import { creditCardIcons } from "../assets/credit-card-icons";

import { config, getUrl } from "../config/config";

import {
	ActiveRun,
	ActiveRunsGroupedByDate,
	AggregatedMachineMetrics,
	AggregatedMetrics,
	AggregatedWebRTCMetrics,
	CategorizedMetricPath,
	InternalPrecondition,
	MetricFilter,
	Metrics,
	ParticipantResult,
	ProjectData,
	Role,
	SortOptions,
	Static,
	UnaggregatedMetrics
} from "../types";

import {
	accountRoles,
	buyerKind,
	cards,
	countries,
	EUCountries,
	metrics,
	pages,
	payment,
	sortFields,
	sorting,
	statics,
	testConsts,
	testStates,
	unitSymbol,
	zeroTimeValue
} from "./const";

import { hasPermission } from "./permission";

const {
	MODE: { MOS, PERFORMANCE, SESSION_RECORD }
} = testConsts;

const { KB, KBT, KBT_SEC, MB, MS, PER_SEC, PERCENT, PX } = unitSymbol;

const { aggregator, metric, type } = metrics;

const { TOTAL } = aggregator;
const {
	BITRATE,
	BYTES,
	CODEC,
	CONNECTIONS,
	CPU,
	FPS,
	FRAME_HEIGHT,
	FRAME_WIDTH,
	JITTER,
	JITTER_BUFFER,
	LEVEL,
	NETWORK,
	PACKETS,
	PACKETS_LOST,
	RAM,
	RTT
} = metric;

const aggregateMetrics = (
	metrics: Partial<UnaggregatedMetrics> = {},
	filters: MetricFilter[]
): AggregatedMetrics | {} => {
	const aggregated = {} as AggregatedMetrics;

	if (filters.length === 0) {
		// If no filtered data is available, then manually push one of the filters
		// so some metric values can be still shown in a table without filters
		filters.push("average");
	}

	filters.forEach(filter => {
		aggregated[filter as keyof AggregatedMetrics] = {};
	});

	Object.keys(aggregated).forEach(filter => {
		Object.keys(metrics || {}).forEach(key => {
			const nonFilterable = metrics[key as keyof UnaggregatedMetrics]?.value;

			if (typeof nonFilterable !== "undefined") {
				(aggregated[filter] as AggregatedMetrics)[key] = nonFilterable;

				return;
			}

			(aggregated[filter] as Partial<AggregatedMetrics>)[
				key as keyof (AggregatedMachineMetrics | AggregatedWebRTCMetrics)
			] = (metrics[key] as Metrics)[filter as keyof Metrics];
		});
	});

	return aggregated;
};

const allowAccessToTB = (email: string, superuser: boolean): boolean => {
	if ((email && email.includes(config.qaDomain as string)) || superuser) {
		return true;
	}

	return false;
};

const appendMachineStatsUnit = (
	aggregator: string,
	metric: string,
	value: string,
	path: string
): string => {
	switch (true) {
		case metric === CPU:
		case aggregator === metrics.aggregator.PERCENT:
		case aggregator === metrics.aggregator.RSTDDEV:
			return `${value} ${PERCENT}`;
		case metric === RAM:
			return path && path.includes(`/${metrics.aggregator.PERCENT}`)
				? `${value} ${PERCENT}`
				: `${value} ${MB}`;
		case metric === BITRATE:
			return aggregator === TOTAL ? `${value} ${KBT}` : `${value} ${KBT_SEC}`;
		case metric === BYTES:
			return `${value} ${KB}`;
		default:
			return aggregator === TOTAL ? value : `${value} ${PER_SEC}`;
	}
};

const appendUnit = (
	metricType: string,
	aggregator: string,
	metric: string,
	value: string,
	path: string
): string => {
	if (metrics.type.MOS === metricType) return value;

	if (metrics.type.ACTIONS === metricType) {
		return `${value} ${unitSymbol.MS}`;
	}

	return metricType === metrics.type.MACHINE
		? appendMachineStatsUnit(aggregator, metric, value, path)
		: appendWebRTCStatsUnit(aggregator, metric, value);
};

const appendWebRTCStatsUnit = (
	aggregator: string,
	metric: string,
	value: string
): string => {
	switch (true) {
		case aggregator === metrics.aggregator.PERCENT:
		case aggregator === metrics.aggregator.RSTDDEV:
			return `${value} ${PERCENT}`;
		case metric === NETWORK:
			return `${value} ${PER_SEC}`;
		case metric === CODEC:
		case metric === CONNECTIONS:
		case metric === LEVEL:
		case metric === FPS:
			return `${value}`;
		case metric === JITTER:
		case metric === JITTER_BUFFER:
		case metric === RTT:
			return `${value} ${MS}`;
		case metric === FRAME_HEIGHT:
		case metric === FRAME_WIDTH:
			return `${value} ${PX}`;
		case metric === PACKETS:
		case metric === PACKETS_LOST:
			return aggregator === TOTAL ? value : `${value} ${PER_SEC}`;
		case metric === BYTES:
			return `${value} ${KB}`;
		default:
			return aggregator === TOTAL ? `${value} ${KBT}` : `${value} ${KBT_SEC}`;
	}
};

const arrayIncludes = (arr: string[] | string, value: string): boolean => {
	if (arr.includes) {
		return arr.includes(value);
	}

	return arr.indexOf(value) > -1;
};

const arrayFind = <T,>(
	objArray: (string | number | {})[],
	key: string,
	match: unknown
): T => {
	if (typeof Array.prototype.find === "function") {
		return objArray.find(elem => elem[key as keyof typeof elem] === match) as T;
	}

	return objArray.filter(
		elem => elem[key as keyof typeof elem] === match
	)[0] as T;
};

const arraysEqual = (arr1: unknown[], arr2: unknown[]): boolean => {
	const difference = arr1
		.filter(elem => !arr2.includes(elem))
		.concat(arr2.filter(elem => !arr1.includes(elem)));

	return difference.length === 0;
};

const categorizeMetricPath = (metricPath: Static[]): CategorizedMetricPath => {
	const statisticTypes: CategorizedMetricPath = {};

	// Generate unique statistic type keys
	metricPath.forEach(path => {
		const [metricType, metric] = path.value.split("/");

		const statisticName = `${metricType}/${metric}`;

		// Create statistic array if not already created
		if (!statisticTypes.hasOwnProperty(statisticName)) {
			statisticTypes[statisticName as keyof typeof statisticTypes] = [];
		}

		// Push corresponding metric paths to statistic array
		statisticTypes[statisticName as keyof typeof statisticTypes].push(
			path.value
		);
	});

	return statisticTypes;
};

const groupActiveRunsByDate = (items: ActiveRun[]): ActiveRunsGroupedByDate => {
	const results: ActiveRunsGroupedByDate = {};

	items.forEach(item => {
		const date = moment(item.created).format("MMM DD, YYYY");

		if (!results[date]) {
			results[date] = [];
		}

		results[date].push(item);
	});

	return results;
};

const noop = (): void => {};

const noPropagationLinkClick = (event: MouseEvent, url: string): void => {
	event.preventDefault();
	window.open(url, "_blank");
};

const objectEmpty = (obj: { [key: string]: unknown }): boolean =>
	Object.keys(obj).length === 0;

const objectEntries = (obj: {
	[key: string]: unknown;
}): [string, unknown][] => {
	if (Object.entries) {
		return Object.entries(obj);
	}

	const ownProps = Object.keys(obj);
	let i = ownProps.length;
	const resArray = new Array(i);

	while (i > 0) {
		i -= 1;

		resArray[i] = [ownProps[i], obj[ownProps[i]]];
	}

	return resArray;
};

const objectEqual = (a: {}, b: {}): boolean => {
	const { isArray } = Array;
	const keyList = Object.keys;
	const hasProp = Object.prototype.hasOwnProperty;

	// Check String, Number and Boolean equality cases
	if (a === b) return true;

	// Typeof object refers to Arrays, Date and RegExp instances, Objects
	if (a && b && typeof a === "object" && typeof b === "object") {
		// Check Array equality case
		const arrA = isArray(a);
		const arrB = isArray(b);
		if (arrA !== arrB) return false;
		if (arrA && arrB) {
			const { length } = a as [];
			if (length !== (b as []).length) return false;
			for (let i = 0; i < length; i += 1) {
				if (!objectEqual((a as [])[i], (b as [])[i])) return false;
			}

			return true;
		}

		// Check Date instance equality case
		const dateA = a instanceof Date;
		const dateB = b instanceof Date;
		if (dateA !== dateB) return false;
		if (dateA && dateB) return (a as Date).getTime() === (b as Date).getTime();

		// Check RegExp instance equality case
		const regexpA = a instanceof RegExp;
		const regexpB = b instanceof RegExp;
		if (regexpA !== regexpB) return false;
		if (regexpA && regexpB) return a.toString() === b.toString();

		// Check Object equality case
		const keys = keyList(a);
		const { length } = keys;
		if (length !== keyList(b).length) return false;
		for (let i = 0; i < length; i += 1) {
			const key = keys[i];
			if (!hasProp.call(b, key)) return false;
			if (!objectEqual(a[key as keyof typeof a], b[key as keyof typeof b]))
				return false;
		}

		return true;
	}

	// Return negative if none of the above cases where met
	return false;
};

const objectValues = (obj: {}): unknown[] => {
	if (Object.values) {
		return Object.values(obj);
	}

	return Object.keys(obj).map(elem => obj[elem as keyof typeof obj]);
};

const compareItems = (item1: unknown, item2: unknown): boolean => {
	// Get the object type
	const itemType = Object.prototype.toString.call(item1);

	// If an object or array, compare recursively
	if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
		// eslint-disable-next-line no-use-before-define
		if (!isEqual(item1, item2)) return false;
	} else {
		// If the two items are not the same type, return false
		if (itemType !== Object.prototype.toString.call(item2)) return false;

		// Else if it's a function, convert to a string and compare
		// Otherwise, just compare
		if (itemType === "[object Function]") {
			if ((item1 as number).toString() !== (item2 as number).toString())
				return false;
		} else if (item1 !== item2) return false;
	}

	return false;
};

const singleParamSort = (prop: string): unknown => {
	let property = prop;
	let sortOrder = 1;

	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}

	return (a: {}, b: {}) => {
		const aValue = a[property as keyof typeof a];
		const bValue = b[property as keyof typeof b];

		const { CREATED, UPDATED } = sortFields;
		const reverseOrder = property === CREATED || property === UPDATED;

		let result = 0;

		if (aValue < bValue) result = reverseOrder ? 1 : -1;
		if (aValue > bValue) result = reverseOrder ? -1 : 1;

		return result * sortOrder;
	};
};

const checkStatisticFilterAvailability = (
	statistics: UnaggregatedMetrics
): MetricFilter[] => {
	const availableFilters: MetricFilter[] = [];

	(objectValues(statistics) as Metrics[]).forEach(metrics => {
		Object.keys(metrics).forEach(filter => {
			if (
				!availableFilters.includes(filter as MetricFilter) &&
				filter !== "id" &&
				filter !== "created" &&
				filter !== "data_count" &&
				filter !== "metric_path" &&
				filter !== "value"
			) {
				availableFilters.push(filter as MetricFilter);
			}
		});
	});

	return availableFilters;
};

const classNames = (classes: unknown[]): string =>
	classes.filter(c => !!c).join(" ");

const deepCopy = <T,>(original: T | T[]): T | T[] => {
	if (Array.isArray(original)) {
		const copy: T[] = [];

		for (const [index, value] of original.entries()) {
			copy[index] = deepCopy(value) as T;
		}

		return copy;
	} else if (typeof original === "object" && original !== null) {
		const copy: Partial<T> = {};

		for (const [key, value] of Object.entries(original)) {
			copy[key as keyof Partial<T>] = deepCopy(value);
		}

		return copy as T;
	}

	return original;
};

const deepEqual = (a: any, b: any): boolean => {
	if (a === b) return true; // Check for primitive values and identical references

	if (a == null || b == null) return false; // Check for null or undefined

	if (typeof a !== "object" || typeof b !== "object") return false; // Check if types are type object

	// Compare object keys and length
	const keysA = Object.keys(a);
	const keysB = Object.keys(b);

	if (keysA.length !== keysB.length) return false;

	// Compare the properties of the objects
	for (const key of keysA) {
		if (!keysB.includes(key)) return false;

		if (!deepEqual(a[key], b[key])) return false;
	}

	return true;
};

const dynamicSort =
	(...args: (string | number | {})[]) =>
	(obj1: {}, obj2: {}): number => {
		let result = 0;

		for (let i = 0; i < args.length; i++) {
			result = (singleParamSort(args[i] as string) as (a: {}, b: {}) => number)(
				obj1,
				obj2
			);

			if (result !== 0) return result;
		}

		return result;
	};

const isEqual = (value: unknown, other: unknown): boolean => {
	// Get the value type
	const type = Object.prototype.toString.call(value);

	// If the two objects are not the same type, return false
	if (type !== Object.prototype.toString.call(other)) return false;

	// If items are not an object or array, return false
	if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

	// Compare the length of the length of the two items
	const valueLen =
		type === "[object Array]"
			? (value as []).length
			: Object.keys(value as {}).length;
	const otherLen =
		type === "[object Array]"
			? (other as []).length
			: Object.keys(other as {}).length;
	if (valueLen !== otherLen) return false;

	// Compare properties
	if (type === "[object Array]") {
		for (let i = 0; i < valueLen; i++) {
			if (compareItems((value as [])[i], (other as [])[i]) === false)
				return false;
		}
	}

	if (
		Object.keys(value as {}).some(
			key =>
				compareItems(
					(value as [])[key as keyof typeof value],
					(other as [])[key as keyof typeof other]
				) === false
		)
	) {
		return false;
	}

	// If nothing failed, return true
	return true;
};

const stringIncludes = (str: string, substr: string): boolean => {
	if (str.includes) {
		return str.includes(substr);
	}
	return str.indexOf(substr) > -1;
};

const asyncRetry = <T,>(
	fn: () => Promise<T>,
	retriesLeft = 3,
	interval = 1000,
	rateLimiting = false
): Promise<T> =>
	new Promise((resolve, reject) => {
		fn().then(resolve, error => {
			const { response } = error || {};

			if (retriesLeft === 1 || (rateLimiting && response?.status !== 429)) {
				return reject(error);
			}

			toast.error(
				i18next.t("ALERT.ERROR.REQUEST_RETRY", {
					count: interval / 1000
				})
			);

			setTimeout(() => {
				asyncRetry(
					fn,
					retriesLeft - 1,
					rateLimiting ? interval * 2 : interval,
					rateLimiting
				).then(resolve, reject);
			}, interval);
		});
	});

const lazyComponent = async (
	importedComponent: Promise<{ default: ComponentType }>
): Promise<{ default: ComponentType } | void> => {
	try {
		return await asyncRetry(() => importedComponent);
	} catch (error) {
		// if fails to re-download failed chunks, then hard-reload the whole app
		window.location.reload();
	}
};

const isMobile = (): boolean =>
	Math.min(window.screen.width, window.screen.height) < 768 ||
	navigator.userAgent.indexOf("Mobi") > -1;

const sleep = (ms: number): Promise<void> =>
	new Promise(resolve => setTimeout(resolve, ms));

const getStatusIcon = (status: string): string => {
	let icon = "";

	switch (status) {
		case testStates.PASS:
			icon = "pass-circle";
			break;
		case testStates.FAIL:
			icon = "fail-circle";
			break;
		case testStates.ABORTED:
			icon = "abort-circle";
			break;
		case testStates.TIMEOUT:
			icon = "timeout";
			break;
		default:
			icon = "warning-circle";
	}

	return icon;
};
const uniqueObjects = (arr: any[], key: string): any => [
	...new Map(arr.map(item => [item[key], item])).values()
];

const snakeToCamel = (str: string): string =>
	str.replace(/([-_][a-z])/g, group => group.toUpperCase().replace("_", ""));

const separateNumericNonNumeric = (str: string): Record<string, string> => {
	const match = str.match(/^(\d+(?:\.\d+)?)(\D.*)$/);

	if (match) {
		return {
			numeric: match[1],
			nonNumeric: match[2]
		};
	}

	return {
		numeric: "",
		nonNumeric: str
	};
};

const sortBrowsers = (browsers: Static[]): Static[] => {
	return browsers.sort((a, b) => {
		const isALatest = a.name.includes("Latest");
		const isBLatest = b.name.includes("Latest");

		if (isALatest && isBLatest) {
			return a.name.localeCompare(b.name);
		}
		if (isALatest) return -1;

		if (isBLatest) return 1;

		const [aName, aVersion] = a.name.split(" ").slice(-2);
		const [bName, bVersion] = b.name.split(" ").slice(-2);

		if (aName !== bName) {
			return aName.localeCompare(bName);
		}

		return Number(bVersion) - Number(aVersion);
	});
};

const sortComputeUnits = (computeUnits: Static[]): Static[] =>
	computeUnits.sort((a, b) => {
		return Number(a.value.slice(1)) - Number(b.value.slice(1));
	});

const sortNetworks = (networks: Static[]): Static[] =>
	networks.sort((a, b) => {
		if (a.value === "default") return -1;
		if (b.value === "default") return 1;

		const isAPacket = a.value.includes("packet");
		const isBPacket = b.value.includes("packet");

		if (isAPacket && isBPacket) {
			return (
				Number(separateNumericNonNumeric(a.value).numeric) -
				Number(separateNumericNonNumeric(b.value).numeric)
			);
		}

		if (isAPacket) return -1;
		if (isBPacket) return 1;

		const aParts = separateNumericNonNumeric(a.name);
		const bParts = separateNumericNonNumeric(b.name);

		if (aParts.numeric && bParts.numeric) {
			return Number(aParts.numeric) - Number(bParts.numeric);
		}

		if (aParts.numeric) return -1;
		if (bParts.numeric) return 1;

		return aParts.nonNumeric.localeCompare(bParts.nonNumeric);
	});

const sortAudioFeed = (audioFeed: Static[]): Static[] => {
	return audioFeed.sort((a, b) => {
		if (a.value === "default") return -1;
		if (b.value === "default") return 1;

		const kbpsA = a.value.match(/^(\d+)kbps$/);
		const kbpsB = b.value.match(/^(\d+)kbps$/);

		if (kbpsA && kbpsB) {
			return Number(kbpsB[1]) - Number(kbpsA[1]);
		}
		if (kbpsA) return -1;
		if (kbpsB) return 1;

		const dbA = a.value.match(/^-([\d.]+)db$/);
		const dbB = b.value.match(/^-([\d.]+)db$/);

		if (dbA && dbB) {
			return parseFloat(dbB[0]) - parseFloat(dbA[0]);
		}

		if (dbA) return -1;
		if (dbB) return 1;

		return a.name.localeCompare(b.name);
	});
};

const sortVideoFeed = (videoFeed: Static[]): Static[] => {
	return videoFeed.sort((a, b) => {
		const getResolution = (name: string): string => {
			const resolutionMatch = name.match(/\b\d+p\b/);
			return resolutionMatch ? resolutionMatch[0].replace("p", "") : "";
		};

		const getFPS = (name: string): number | null => {
			const fpsMatch = name.match(/\s@\s(\d+)FPS/);
			return fpsMatch ? Number(fpsMatch[1]) : null;
		};

		if (a.value === "default") return -1;
		if (b.value === "default") return 1;

		const aResolution = getResolution(a.name);
		const bResolution = getResolution(b.name);

		const aFPS = getFPS(a.name);
		const bFPS = getFPS(b.name);

		if (aResolution !== bResolution) {
			return Number(bResolution) - Number(aResolution);
		}

		if (aFPS !== null && bFPS !== null && aFPS - bFPS !== 0) {
			return aFPS - bFPS;
		}

		const isADynamic = a.name.includes("dynamic");
		const isBDynamic = b.name.includes("dynamic");

		if (isADynamic && !isBDynamic) return -1;
		if (!isADynamic && isBDynamic) return 1;

		const isATopLeft = a.name.includes("top left");
		const isBTopLeft = b.name.includes("top left");

		if (isATopLeft && !isBTopLeft) return 1;
		if (!isATopLeft && isBTopLeft) return -1;

		const isACleanMeeting = a.name.includes("clean meeting");
		const isBCleanMeeting = b.name.includes("clean meeting");

		if (isACleanMeeting && !isBCleanMeeting) return 1;
		if (!isACleanMeeting && isBCleanMeeting) return -1;

		return a.name.localeCompare(b.name);
	});
};

const sortTestDuration = (testDuration: Static[]): Static[] => {
	return testDuration.sort((a, b) => {
		return getMinutes(a.value) - getMinutes(b.value);
	});
};

const sortOperators = (operators: Static[]): Static[] => {
	const order = ["eq", "neq", "lt", "lte", "gt", "gte", "regex"];

	return operators.sort(
		(a, b) => order.indexOf(a.value) - order.indexOf(b.value)
	);
};

const sortRecords = (sortBy: SortOptions): ((obj1: {}, obj2: {}) => number) => {
	const { CREATED, UPDATED } = sortFields;

	switch (sortBy) {
		case sorting.NEWEST:
			return dynamicSort(CREATED);
		case sorting.LAST_UPDATED:
			return dynamicSort(UPDATED);
		case sorting.OLDEST:
			return dynamicSort(`-${CREATED}`);
		default:
			return dynamicSort(CREATED);
	}
};

const getMinutes = (value: string): number => {
	if (value.endsWith("m")) {
		return parseInt(value, 10);
	}

	if (value.endsWith("h")) {
		return parseInt(value, 10) * 60;
	}

	return 0;
};

const getRemainingTrialTime = (startTime: string): [] | number[] => {
	if (!startTime) return [];

	const timeNow = moment.utc();
	const trialEnd = moment.utc(startTime).add(14, "d");

	return [trialEnd.diff(timeNow, "days"), trialEnd.diff(timeNow, "hours")];
};

const getEnvLink = (url: string, landing?: boolean): string => {
	const baseURL = getUrl(landing);

	const { PRICING, PRIVACY_POLICY, TERMS_AND_CONDITIONS } = pages.LANDING;
	const { HOME, REGISTER } = pages.APP;

	switch (url) {
		case HOME:
			return baseURL;
		case PRICING:
			return `${baseURL}/${PRICING}`;
		case PRIVACY_POLICY:
			return `${baseURL}/${PRIVACY_POLICY}`;
		case REGISTER:
			return `${baseURL}/${REGISTER}`;
		case TERMS_AND_CONDITIONS:
			return `${baseURL}/${TERMS_AND_CONDITIONS}`;
		default:
			return "";
	}
};

const getMetricInfoFromPath = (metricPath: string): string[] => {
	const splittedPath = metricPath.split("/");

	const aggregator = splittedPath[splittedPath.length - 1];
	const metricType = splittedPath[0];
	let metric = splittedPath[2];

	if (metricType === type.MACHINE) {
		metric = splittedPath[1];

		if (splittedPath[1] === NETWORK) {
			metric = splittedPath[2];
		}
	}

	return [aggregator, metricType, metric];
};

const getCardIcon = (brand: string): JSX.Element => {
	const brandAvailable = Object.values(cards).some(card => card === brand);

	return (
		<SVGIcon
			className="card-icon"
			width={34}
			iconName={brandAvailable ? brand : "card-front"}
			customIconSet={creditCardIcons}
		/>
	);
};

const mapCodeToCountry = (code: string): string => {
	const country = countries.find(({ key }) => key === code);

	return country ? country.label : code;
};

const getUpgradableProjects = (projects: ProjectData[]): ProjectData[] => {
	if (!Array.isArray(projects) || projects.length < 1) return [];

	const { ADMINISTRATOR } = accountRoles;
	const {
		status: { SKIP_RENEWAL, SUCCESS }
	} = payment;

	return projects.filter(
		p =>
			hasPermission(p.account_role as Role, ADMINISTRATOR.key) &&
			p.subscription?.payment_status !== SUCCESS &&
			p.subscription?.payment_status !== SKIP_RENEWAL
	);
};

const staticsPropRetriever = (
	obj: ParticipantResult,
	propName: string
): string | null => {
	const {
		AUDIO_FEED,
		BROWSER,
		COMPUTE_UNIT,
		LOCATION,
		NETWORK,
		STATUS,
		VIDEO_FEED
	} = statics;
	const { audio_feed, browser, compute_unit, location, network, video_feed } =
		obj?.participant_details || {};

	switch (propName) {
		case AUDIO_FEED:
			return audio_feed?.name;
		case BROWSER:
			return browser?.name;
		case COMPUTE_UNIT:
			return (compute_unit as Static)?.name;
		case LOCATION:
			return location?.name;
		case NETWORK:
			return network?.value;
		case STATUS:
			return obj?.status?.value;
		case VIDEO_FEED:
			return video_feed?.name;
		default:
			return null;
	}
};

const separateThousands = (n = 0, separator = " "): string =>
	n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);

const staticToOption = (statics: Static[]): Option[] =>
	statics.map(s => ({
		key: s.value,
		label: s.name
	}));

const allEqual = (arr: (string | number)[]): boolean =>
	arr.every(v => v === arr[0]);

const getTestRunTooltipText = (
	testMode: string,
	count = 0,
	hasPermission: boolean
): string | null => {
	switch (true) {
		case !hasPermission:
			return "TEST.RUN_BUTTON_TOOLTIP.NO_PERMISSION";
		case count < 1:
			return "TEST.RUN_BUTTON_TOOLTIP.NO_PARTICIPANTS";
		case testMode === MOS.key && count > MOS.max:
			return "TEST.RUN_BUTTON_TOOLTIP.MOS";
		case testMode === PERFORMANCE.key && count > PERFORMANCE.max:
			return "TEST.RUN_BUTTON_TOOLTIP.PERFORMANCE";
		case testMode === SESSION_RECORD.key && count > SESSION_RECORD.max:
			return "TEST.RUN_BUTTON_TOOLTIP.SESSION_RECORDING";
		default:
			return null;
	}
};

const shouldDisableTestRun = (
	mode: string,
	count: number,
	activeRun: boolean,
	hasPermission: boolean
): boolean => {
	return (
		!hasPermission ||
		(!activeRun &&
			(!count ||
				count < 1 ||
				(mode === PERFORMANCE.key && count > PERFORMANCE.max) ||
				(mode === SESSION_RECORD.key && count > SESSION_RECORD.max)))
	);
};

const checkPreconditions = (list: InternalPrecondition[]): boolean =>
	(list || []).every((_, i) => {
		if (!list[i].expected) {
			// Precondition isn't finished
			return false;
		}

		// Precondition has all fields selected
		return true;
	});

const checkID = (id: string): string => {
	const numeric = /^-?\d+$/;

	return numeric.test(id) ? id : "0";
};

const isEU = (country: string): boolean =>
	Object.keys(EUCountries).some(c => c === country);

const shouldBeTaxed = (
	country: string,
	buyerType: string,
	vatProvided: boolean
): boolean =>
	country === EUCountries.LV ||
	buyerType === buyerKind.PRIVATE ||
	(!vatProvided && isEU(country));

const valueExists = (val: unknown): boolean =>
	val !== null &&
	typeof val !== "undefined" &&
	val !== "" &&
	val !== zeroTimeValue;

const filterAbortedResults = (
	participants: { [key: string]: unknown }[]
): { [key: string]: unknown }[] =>
	(participants || []).filter(
		p => valueExists(p.start as string) && valueExists(p.end as string)
	);

const generateUUID = (): string => {
	let d = new Date().getTime();
	let d2 =
		(typeof performance !== "undefined" &&
			performance.now &&
			performance.now() * 1000) ||
		0;

	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
		let r = Math.random() * 16;

		if (d > 0) {
			r = (d + r) % 16 | 0;
			d = Math.floor(d / 16);
		} else {
			r = (d2 + r) % 16 | 0;
			d2 = Math.floor(d2 / 16);
		}

		return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
	});
};

export {
	aggregateMetrics,
	allEqual,
	allowAccessToTB,
	appendMachineStatsUnit,
	appendUnit,
	appendWebRTCStatsUnit,
	arrayFind,
	arrayIncludes,
	arraysEqual,
	asyncRetry,
	categorizeMetricPath,
	checkID,
	checkPreconditions,
	checkStatisticFilterAvailability,
	classNames,
	deepCopy,
	deepEqual,
	dynamicSort,
	filterAbortedResults,
	generateUUID,
	getCardIcon,
	getEnvLink,
	getMetricInfoFromPath,
	getMinutes,
	getRemainingTrialTime,
	getStatusIcon,
	getTestRunTooltipText,
	getUpgradableProjects,
	groupActiveRunsByDate,
	isEqual,
	isEU,
	isMobile,
	lazyComponent,
	mapCodeToCountry,
	noop,
	noPropagationLinkClick,
	objectEmpty,
	objectEntries,
	objectEqual,
	objectValues,
	separateThousands,
	shouldBeTaxed,
	shouldDisableTestRun,
	sleep,
	snakeToCamel,
	sortAudioFeed,
	sortBrowsers,
	sortComputeUnits,
	sortNetworks,
	sortOperators,
	sortRecords,
	sortTestDuration,
	sortVideoFeed,
	staticsPropRetriever,
	staticToOption,
	stringIncludes,
	uniqueObjects,
	valueExists
};
