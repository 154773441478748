import { type NavigateFunction } from "react-router-dom";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { AxiosError } from "axios";

import { runAPI } from "../../api/v2";

import { fireGTMEvent } from "../../services/googleTag";

import { store } from "../store/configureStore";

import {
	ActiveRun,
	APIError,
	ProcessedActiveRuns,
	RequestOptions,
	ResponseWithPaging,
	RunParams
} from "../../types";

import { runTest, stopTestRun } from "../../utils/testRun";
import { EVENT_KEYS } from "../../utils/const/events";

export interface IActiveRunsReducer {
	currentlyFetching: string[];
	runs: ProcessedActiveRuns | {};
	runIDToFetchLabels: number;
	errorMessage: AxiosError | unknown | null;
	isFetching: boolean;
	loaded: boolean;
}

const initialState: IActiveRunsReducer = {
	currentlyFetching: [],
	runs: {},
	runIDToFetchLabels: 0,
	errorMessage: null,
	isFetching: false,
	loaded: false
};

export const fetchActiveRuns = createAsyncThunk(
	"activeRuns/fetchActiveRuns",
	async (
		{
			navigate,
			projectID,
			options
		}: {
			navigate: NavigateFunction;
			projectID: number;
			options?: RequestOptions;
		},
		{ rejectWithValue }
	): Promise<ResponseWithPaging<ActiveRun> | APIError | {}> => {
		const params = { projectID };
		const queryParams = {
			active: true,
			describe: true,
			filter_finished: false
		};

		try {
			return await runAPI.PROJECT.readAll(
				navigate,
				params,
				queryParams,
				options || {}
			);
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const addRun = createAsyncThunk(
	"activeRuns/addRun",
	async (
		{
			navigate,
			params,
			options
		}: {
			navigate: NavigateFunction;
			params: RunParams;
			options?: RequestOptions;
		},
		{ dispatch, rejectWithValue }
	): Promise<ActiveRun | APIError | {}> => {
		try {
			const { account } = store.getState();
			const response = await runTest(params, navigate, options || {});

			await dispatch(
				fetchActiveRuns({ navigate, projectID: params.projectID as number })
			).unwrap();

			fireGTMEvent({
				event: EVENT_KEYS.TEST.TEST_RUN_CREATE,
				account_id: account.accountData.id
			});

			return (response as ActiveRun).test_id;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const stopRun = createAsyncThunk(
	"activeRuns/stopRun",
	async (
		{
			navigate,
			params,
			options
		}: {
			navigate: NavigateFunction;
			params: Required<RunParams>;
			options: RequestOptions;
		},
		{ rejectWithValue }
	): Promise<ProcessedActiveRuns | APIError | {}> => {
		try {
			const { activeRuns } = store.getState();
			const runs = { ...activeRuns.runs } as ProcessedActiveRuns;

			await stopTestRun(params, navigate, options || {});

			return (runs as ProcessedActiveRuns).test_id;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

const activeRunsSlice = createSlice({
	name: "activeRuns",
	initialState,
	reducers: {
		setRuns: (state, action: { payload: ActiveRun[] }) => {
			const runs = {} as ProcessedActiveRuns;

			action.payload.forEach((run: ActiveRun) => {
				const { id, test_id } = run;

				if (!runs[test_id]) runs[test_id] = {};
				runs[test_id][id] = run;
			});

			state.runs = runs;
			state.isFetching = false;
		},
		setLoaded: (state, action: { payload: boolean }) => {
			state.loaded = action.payload;
		},
		setRunIDToFetchLabels: (state, action: { payload: number }) => {
			state.runIDToFetchLabels = action.payload;
		},
		clearActiveRunsData: () => {
			return initialState;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchActiveRuns.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(fetchActiveRuns.fulfilled, (state, action) => {
				if ("results" in action.payload) {
					const { results } = action.payload;
					const runs = {} as ProcessedActiveRuns;

					results.forEach((run: ActiveRun) => {
						const { id, test_id } = run;

						if (!runs[test_id]) runs[test_id] = {};
						runs[test_id][id] = run;
					});

					state.runs = runs;
				}

				state.isFetching = false;
			})
			.addCase(fetchActiveRuns.rejected, (state, action) => {
				state.errorMessage = action.payload || null;
				state.isFetching = false;
			})
			.addCase(addRun.pending, (state, action) => {
				state.currentlyFetching.push(action.meta.arg.params.testID);
				state.errorMessage = null;
			})
			.addCase(addRun.fulfilled, (state, action) => {
				state.currentlyFetching = state.currentlyFetching.filter(
					id => id !== action.payload
				);
			})
			.addCase(addRun.rejected, (state, action) => {
				state.errorMessage = action.payload || null;
				state.isFetching = false;
				state.currentlyFetching = [];
			})
			.addCase(stopRun.pending, (state, action) => {
				state.currentlyFetching.push(action.meta.arg.params.testID);
				state.errorMessage = null;
			})
			.addCase(stopRun.fulfilled, (state, action) => {
				state.currentlyFetching = state.currentlyFetching.filter(
					id => id !== action.payload
				);
			})
			.addCase(stopRun.rejected, (state, action) => {
				state.errorMessage = action.payload || null;
				state.isFetching = false;
				state.currentlyFetching = [];
			});
	}
});

export const {
	setRuns,
	setLoaded,
	setRunIDToFetchLabels,
	clearActiveRunsData
} = activeRunsSlice.actions;

export default activeRunsSlice.reducer;
