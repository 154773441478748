import axios from "axios";

import { config } from "../../config/config";
import { store as reduxStore } from "../../redux/store/configureStore";
import { queryKeys } from "../../utils/const";

const mediaAPIRest = axios.create({
	baseURL: `${config.mediaAPIURL}/v2`
});

mediaAPIRest.interceptors.request.use(
	conf => {
		const { baseURL, url } = conf;

		if (reduxStore.getState().persistedStorage.is_admin_mode) {
			const prefix =
				new URL(((baseURL as string) + url) as string).search === ""
					? "?"
					: "&";
			conf.url += `${prefix}${queryKeys.ADMIN}=true`;
		}

		return conf;
	},
	error => Promise.reject(error)
);

export { mediaAPIRest };
