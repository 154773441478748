const hashParams = {
	ACCOUNT: "#account",
	API_ACCESS: "#api-access",
	ARTIFACTS: "#artifacts",
	ASSERTS: "#asserts",
	BILLING_AND_INVOICE: "#billing-and-invoice",
	DETAILS: "#details",
	LABELS: "#labels",
	LOGS: "#logs",
	MACHINE_STATS: "#machine-stats",
	MEMBERS: "#members",
	PARTICIPANTS: "#participants",
	PASSWORD: "#password",
	PLAN_AND_USAGE: "#plan-and-usage",
	PREFERENCES: "#preferences",
	PROFILE: "#profile",
	SCRIPT: "#script",
	SUMMARY: "#summary",
	WEBRTC: "#webrtc"
};

const routes = {
	ACCOUNTS: "/accounts",
	ACTIVATE: "/activate",
	ADD: "/add",
	ASSERTS: "/asserts",
	AUTH: "/auth",
	AUTH_TOKEN: "/auth/token",
	AUTHORIZE: "/authorize",
	AVATAR: "/avatar",
	BILLING: "/billing",
	BLOG: "/blog",
	BULK: "/bulk",
	CARDS: "/cards",
	COPY: "/copy",
	CREATE_PROJECT: "/create-project",
	CURRENT: "/current",
	EDIT: "/edit",
	EMAIL: "/email",
	EXTENDED: "/extended",
	FAVORITES: "/favorites",
	FILES: "/files",
	FORGOT_PASSWORD: "/forgot-password",
	FORGOT_PASSWORD_: "/forgot_password",
	GROUPS: "/groups",
	HOME: "/home",
	INVITED: "/invited",
	LABELS: "/labels",
	LOG_IN: "/login",
	LOG_OUT: "/logout",
	MEMBERS: "/members",
	NAME: "/name",
	PAGE_NOT_FOUND: "/page-not-found",
	PARTICIPANTS: "/participants",
	PRECONDITIONS: "/preconditions",
	PRESIGNED: "/presigned",
	PRICING: "/pricing",
	PROJECTS: "/projects",
	PROXY: "/proxy",
	REGISTER: "/register",
	RESEND: "/resend",
	RESET_PASSWORD: "/reset-password",
	RESET_PASSWORD_: "/reset_password",
	RESULTS: "/results",
	ROOT: "/",
	RUNS: "/runs",
	SETTINGS: "/settings",
	SETUP_INTENT: "/setup_intent",
	SIGN_UP: "/signup",
	STATIC: "/statics",
	STATISTICS: "/statistics",
	STOP: "/stop",
	SUBSCRIPTIONS: "/subscriptions",
	TEST_BUILDER: "/test-builder",
	TESTS: "/tests",
	TOKENS: "/tokens",
	TRENDS: "/trends",
	TRIAL: "/trial",
	UPGRADE: "/upgrade",
	USERS: "/users",
	VAT: "/vat",
	VERIFY: "/verify",
	WEB_RTC_GUIDE_API: "/webrtc_guide"
};

const publicRoutes = new Set([
	routes.ROOT,
	routes.RESET_PASSWORD,
	routes.RESET_PASSWORD_,
	routes.FORGOT_PASSWORD,
	routes.FORGOT_PASSWORD_,
	routes.AUTHORIZE,
	routes.REGISTER,
	routes.SIGN_UP,
	routes.LOG_IN,
	routes.LOG_OUT,
	routes.BLOG
]);

const routeParams = {
	fileID: ":fileID",
	groupID: ":groupID",
	participantID: ":participantID",
	projectID: ":projectID",
	runID: ":runID",
	testID: ":testID"
};

const localStorageRoutes = {
	REDIRECT_URL_AFTER_LOGIN: "redirect_url_after_login"
} as const;

export { hashParams, routes, routeParams, publicRoutes, localStorageRoutes };
